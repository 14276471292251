<template>
  <div>
    <div v-if="query.in_queue && !query.latest_result" class="card">
      <div class="progress progress-sm card-progress">
        <div
          class="progress-bar progress-bar-indeterminate"
          role="progressbar"
        ></div>
      </div>

      <div class="card-body">
        <h3>Currently searching for &amp; analyzing your query.</h3>
        <p>
          Hang on while we perform a search for and analyze your query. This
          shouldn't take more than a minute. When the report is complete, it
          will load automatically.
        </p>
      </div>
    </div>
    <div v-else-if="query.in_queue">
      <div class="alert alert-info">
        This result is currently being updated. When a new version is available,
        it will load automatically.
      </div>
    </div>

    <div v-if="query && query.latest_result">
      <div class="row mb-3" v-if="query.reputation_providers">
        <div
          class="col"
          v-for="(rp, idx) in rating_providers"
          :key="`${idx}-rep`"
        >
          <div class="card">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span class="bg-gray text-white avatar">
                    <img
                      v-if="image"
                      :src="`https://logo.clearbit.com/${rp.domain}`"
                    />
                    <!-- Download SVG icon from http://tabler-icons.io/i/currency-dollar -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      v-else
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        d="M16.7 8a3 3 0 0 0 -2.7 -2h-4a3 3 0 0 0 0 6h4a3 3 0 0 1 0 6h-4a3 3 0 0 1 -2.7 -2"
                      ></path>
                      <path d="M12 3v3m0 12v3"></path>
                    </svg>
                  </span>
                </div>
                <div class="col">
                  <div class="h3 mb-0 d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon me-2"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"
                      ></path>
                    </svg>

                    <span
                      >{{ rp.aggregate_rating }} /
                      {{ rp.max_rating }} -&nbsp;</span
                    >
                    <span class="text-muted"
                      >{{ rp.rating_count }} ratings</span
                    >
                  </div>

                  <div class="text-muted">
                    <a :href="rp.raw_domain" target="_blank">
                      On {{ rp.domain }}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-external-link"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path
                          d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5"
                        ></path>
                        <line x1="10" y1="14" x2="20" y2="4"></line>
                        <polyline points="15 4 20 4 20 9"></polyline>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-8">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Trust Tips</h3>
            </div>

            <div class="list-group list-group-flush">
              <div class="list-group-header sticky-top">Knowledge Panel</div>
              <div
                class="list-group-item bg-white"
                v-for="(tip, idx) in query.latest_result.tips.knowledge"
                :key="`${idx}-knowledge-tip`"
              >
                <tip :tip="tip"></tip>
              </div>

              <div class="list-group-header sticky-top">
                Organic Search Results
              </div>
              <div
                class="list-group-item bg-white"
                v-for="(tip, idx) in query.latest_result.tips.results"
                :key="`${idx}-results-tip`"
              >
                <tip :tip="tip"></tip>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mb-3">
            <div class="card-body">
              <p class="pb-0">
                <strong>Search Term:</strong> {{ query.search_term }}<br />
                <strong>Searching From:</strong> {{ query.location }}<br />
                <strong>This search's URLs:</strong>
              </p>
              <ul>
                <li v-for="(url, idx) in query.urls" :key="`${idx}-url-list`">
                  {{ url.url }}
                </li>
              </ul>
              <p>
                <span class="text-muted small">
                  Last updated
                  {{ relativeDate(query.latest_result.create_date) }}.
                  <a href="#" @click.prevent="refresh(true)">Update now.</a>
                </span>
              </p>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h3>About Trust Insights</h3>
              <p>
                Our algorithm takes Search Result Pages from you or your
                competitors, and builds trust insights to demonstrate what
                potential customers might see when performing research in the
                consideration phase of the purchasing journey.
                <strong>
                  Your Trust Insights reports are not shared and cannot be
                  viewed by anyone outside of your company.
                </strong>
              </p>
              <p class="text-muted">
                The Trust Insights report is currently in
                <span class="badge">BETA</span>. There may be bugs, unexpected
                behavior and incorrect information.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReputationManagementService from "../../../services/ReputationManagementService";
import Tip from "./Tip.vue";
import { formatDistanceToNow, parseISO } from "date-fns";

export default {
  components: { Tip },
  name: "reputation.search-score.query",
  props: {
    query: Object,
  },
  data() {
    return {
      image: true,
    };
  },
  computed: {
    rating_providers() {
      return [...this.query.reputation_providers]
        .sort((a, b) => b.rating_count - a.rating_count)
        .slice(0, 3);
    },
  },
  methods: {
    relativeDate(date) {
      if (!date) return "";
      return formatDistanceToNow(parseISO(date));
    },
    async refresh(force) {
      if ((this.query && !this.query.latest_result) || force) {
        await ReputationManagementService.refreshQuery(
          this.$auth.activeCompany,
          this.query
        );
      }

      if (force) {
        this.query.in_queue = true;
        await this.reload();
      }
    },
    reload() {
      if (this.query.in_queue) {
        this.$emit("requestReload");
        setTimeout(() => this.reload(), 15 * 1000);
      }
    },
  },
  async mounted() {
    await this.refresh(false);
    await this.reload();
  },
};
</script>
