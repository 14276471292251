<template>
  <div class="row">
    <div class="col-auto">
      <span class="avatar">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-check"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          v-if="tip.impact > 0"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M5 12l5 5l10 -10"></path>
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          v-else
          class="icon icon-tabler icon-tabler-x"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </span>
    </div>
    <div class="col">
      <div class="">
        {{ tip.tip }}
        <a :href="tip.link" v-if="tip.link" target="_blank"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-external-link"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path
              d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5"
            ></path>
            <line x1="10" y1="14" x2="20" y2="4"></line>
            <polyline points="15 4 20 4 20 9"></polyline></svg
        ></a>
      </div>
      <div class="text-muted">{{ tip.details }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reputation.search-score.tip",
  props: {
    tip: Object,
  },
};
</script>
