<template>
  <div v-if="loaded">
    <b-tabs v-model="showTab" content-class="mt-3">
      <b-tab
        :title="query.search_term"
        v-for="(query, idx) in queries"
        :key="`query-${idx}`"
      >
        <query @requestReload="getQueries" :query="query"></query>
      </b-tab>
      <b-tab title="Add New">
        <add-query @saved="newQuery"></add-query>
      </b-tab>
    </b-tabs>
  </div>
</template>

<style lang="scss" scoped>
.positive {
  color: #85c075;

  &::before {
    content: "+";
  }
}

.negative {
  color: #b80f0a;
  &::before {
    content: "-";
  }
}

.left-chart {
  width: 48px;
  max-width: 48px;
}
</style>

<script>
import ReputationManagementService from "../../services/ReputationManagementService";
import AddQuery from "./SearchScore/AddQuery.vue";
import Query from "./SearchScore/Query.vue";

export default {
  components: { AddQuery, Query },
  name: "reputation.search-score",

  data: () => {
    return {
      loaded: false,
      result: null,
      showTab: 0,
      sections: {
        knowledge: {
          name: "Knowledge Panel",
        },
        results: {
          name: "Organic Results",
        },
        ads: {
          name: "Paid Search Ads",
        },
      },

      queries: [],
    };
  },
  methods: {
    async newQuery(query) {
      console.log(query);
      await this.getQueries();
      this.$nextTick(() => {
        this.showTab = this.queries.length - 1;
      });
    },
    sectionScore(section) {
      let improvements = this.sectionImprovements(section);
      let total = improvements
        .map((i) => i.importance)
        .filter((i) => i < 0)
        .reduce((a, c) => a + c, 0);
      console.log(section, improvements, total);
      return 100 + total;
    },
    sectionImprovements(section) {
      if (!this.result) {
        return [];
      }
      return this.result.improvements.filter((x) => x.section === section);
    },

    async getQueries() {
      this.queries = await ReputationManagementService.getQueries(
        this.$auth.activeCompany
      );
    },
  },
  async mounted() {
    this.result = await ReputationManagementService.getCompanySerpScan(
      this.$auth.activeCompany
    );
    await this.getQueries();

    this.loaded = true;
  },
};
</script>
