<template>
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <h3>Understand how your customers see your brand.</h3>
        <p>
          Get insights into how your brand is appearing on Google. Add a new
          query to get started.
        </p>

        <div class="row mb-4">
          <div class="col-md-6">
            <label class="form-label" for="search">Search Term</label>
            <input
              id="search"
              type="text"
              class="form-control"
              v-model="query.term"
            />
          </div>
          <div class="col-md-6 border-start">
            <label class="form-label">&nbsp;</label>

            <p class="small help text-muted">
              Enter the search term you want insight into. We will perform a
              search on this term and let you know how your brand is performing
              in the eyes of potential customers. The search term should be what
              you expect customers to look up to find you on Google.
            </p>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-6">
            <label class="form-label">Your URLs</label>
            <div
              class="input-group mb-2"
              v-for="(url, idx) in query.urls"
              :key="`url-${idx}`"
            >
              <input
                type="text"
                class="form-control"
                placeholder="https://example.com/"
                v-model="query.urls[idx]"
              />
              <button
                class="btn btn-outline-secondary btn-icon"
                type="button"
                @click="query.urls.splice(idx, 1)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-x"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>

            <button
              class="btn btn-sm btn-outline-primary"
              @click.prevent="query.urls.push('')"
            >
              Add Another URL
            </button>
          </div>
          <div class="col-md-6 border-start">
            <label class="form-label">&nbsp;</label>

            <p class="small help text-muted">
              Add your company's URL or URLs, which are used to identify which
              results link back to your company. If your company has multiple
              URLs, such as a ".com" URL and a ".org" URL, enter them both.
            </p>
          </div>
        </div>

        <button
          :class="['btn', 'btn-primary', canAdd ? '' : 'disabled']"
          @click="save"
        >
          Save New Search
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ReputationManagementService from "../../../services/ReputationManagementService";
export default {
  name: "reputation.search-score.add-query",
  data() {
    return {
      query: {
        term: null,
        urls: [null],
        location: "New York,NY,United States",
      },
      locations: [
        "New York,NY,United States",
        "Dallas,Texas,United States",
        "Boston,Massachusetts,United States",
        "Los Angeles,CA,California,United States",
        "San Francisco Bay Area,United States",
        "Miami-Ft. Lauderdale,FL,Florida,United States",
        "Tampa-St Petersburg (Sarasota),FL,Florida,United States",
        "Atlanta,GA,United States",
        "Greater London,England,United Kingdom",
        "Montreal,Quebec,Canada",
        "Toronto,Ontario,Canada",
      ],
    };
  },
  computed: {
    canAdd() {
      return (
        this.query.term != "" &&
        this.query.urls.filter((u) => u && u != "").length > 0
      );
    },
  },
  methods: {
    reset() {
      this.query = {
        term: null,
        urls: [null],
        location: "New York,NY,United States",
      };
    },
    async save() {
      let newTab = await ReputationManagementService.addQuery(
        this.$auth.activeCompany,
        this.query
      );
      this.$emit("saved", newTab);
      this.reset();
    },
  },
};
</script>
